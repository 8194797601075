.header {
  text-align: center;
  font-weight: 700;
  font-size: 3em;
}

.logo {
  text-align: center;
  margin: auto;
  width: 358px;
}
.placelogg {
  text-align: center;
  margin: auto;
  width: 131px;
}

.heading {
  font-weight: 400;
  font-size: 2em;
}

.colored {
  color: #ff4040;
  font-weight: 600;
  font-size: 1.5em;
}

.footer {
  color: #a0a0a0;
}

.container {
  height: 100px;
  //border: dashed blue 1px;
}

.container img {
  max-height: 100%;
  max-width: 100%;
}

.footer a:link {
  color: #a0a0a0;
  text-decoration: underline;
}

.footer a:visited {
  color: #a0a0a0;
  text-decoration: underline;
}

.footer a:hover {
  color: #a0a0a0;
  text-decoration: underline;
}

.footer a:active {
  color: #a0a0a0;
  text-decoration: underline;
}
